import "./styles.scss";

import { Loader } from "@googlemaps/js-api-loader";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

import config, { VERSION } from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  ApiOrderModel,
  ApiStoreModel,
  locales,
  MenuModels,
  StoreModules,
  StoreReduxModels,
  UserReduxAction,
} from "gyg_common";
import ErrorView from "gyg_common/components/Error/ErrorView";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import StoresList from "gyg_common/components/Stores/StoresList";
import {
  getCurrentLocationPermission,
  getUserLocation,
  requestLocationPermission,
} from "modules/location";
import { Screens } from "navigation/const";
import StoreFilter from "views/components/Stores/StoreFilter";
import StoreSearch, {
  ResultListItem,
} from "views/components/Stores/StoreSearch";
import StoresMap from "views/components/Stores/StoresMap";
import ToggleStoresView from "views/components/Stores/ToggleStoresView";

import { StoreLinkModal } from "./StoreLinkModal";

import colours from "@/styles/colours";

const loader = new Loader({
  apiKey: config.googleMapsKey || "",
  libraries: ["places"],
  id: config.googleMapsScriptId,
});

const styles = StyleSheet.create({
  mainContainer: { flex: 1 },
  container: { flex: 1, borderRadius: 8 },
  restaurantsContainer: { flexGrow: 1 },
  mapFilter: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  header: {
    zIndex: 500,
  },
});

export interface RestaurantsProps {
  previousScreen: string;
  recentOrdersToAddToCart?: ApiOrderModel.BasketItem[];
  menuStructure?: MenuModels.MenuStructure;
  locationPermissionGranted: boolean;
  userConfirmedOrderSetup: boolean;
  store: StoreReduxModels.StoreState;
  handleSetMessageToast: (toastMessage: string | null) => void;
  handleStoreSearch: (payload: StoreReduxModels.StoreSearchPayload) => void;
  handleGooglePlaceDetailSearchSuccess: (
    payload: StoreReduxModels.SortStoreByDistanceProps
  ) => void;
  handleSortStoresByDistance: (
    payload: StoreReduxModels.SortStoreByDistanceProps
  ) => void;
  handleSetStoreInfo: (payload: StoreReduxModels.Store | null) => void;
  handleSetStore: (payload: StoreReduxModels.Store) => void;
  handleSetStoreSearchLocation: (
    payload: StoreModules.StoreMapper.StoreCoordinates | null
  ) => void;
  handleSetNearestStoreAsSelected: (
    payload: StoreReduxModels.NearestStoreProps
  ) => void;
}

const Restaurants: React.FC<RestaurantsProps> = ({
  locationPermissionGranted,
  store,
  handleSetMessageToast,
  handleStoreSearch,
  handleGooglePlaceDetailSearchSuccess,
  handleSetStore,
  handleSetStoreInfo,
  handleSetStoreSearchLocation,
  handleSortStoresByDistance,
  handleSetNearestStoreAsSelected,
  previousScreen,
}) => {
  // hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isTabletScreen } = useMediaQuery();

  // variables
  const { DISTANCETHRESHOLD_AU, DISTANCETHRESHOLD_US } =
    StoreModules.StoreUtils;
  const { getStoresLoading, storeTags, searchResult, storeSearchLocation } =
    store;
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();
  // local state
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>();
  const [placeService, setPlaceService] =
    useState<google.maps.places.PlacesService>();

  const [placeResults, setplaceResults] = useState<
    StoreReduxModels.GooglePlaceSearchResult[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [stores, setStores] = useState<StoreReduxModels.Store[]>([]);
  const [selectedTags, updateSelectedTags] = useState<
    StoreModules.StoreModels.StoreTagLabel[]
  >([]);
  const [storeSearchFocused, setStoreSearchFocused] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [userLocation, setUserLocation] =
    useState<StoreModules.StoreMapper.StoreCoordinates | null>(null);

  const [viewType, setViewType] =
    useState<StoreModules.StoreModels.RestaurantsViewType>(
      StoreModules.StoreModels.RestaurantsViewType.LIST
    );
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [storeShareLink] = useState<string>("");

  const sortStoresByDistance = useCallback(
    (payload: StoreReduxModels.SortStoreByDistanceProps) =>
      handleSortStoresByDistance(payload),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSortStoresByDistance]
  );

  const setNearestStoreAsSelected = useCallback(
    (payload: StoreReduxModels.NearestStoreProps) =>
      handleSetNearestStoreAsSelected(payload),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSetNearestStoreAsSelected]
  );

  useEffect(() => {
    const handleScriptLoad = async () => {
      const { AutocompleteService, PlacesService } =
        await loader.importLibrary("places");
      const googleAutoCompleteService = new AutocompleteService();
      const googlePlaceService = new PlacesService(
        document.createElement("div")
      );
      setAutocompleteService(googleAutoCompleteService);
      setPlaceService(googlePlaceService);
    };

    handleScriptLoad();
  }, []);

  const getStoreSearchViewType = (): "List" | "Map" => {
    return `${viewType.substring(0, 1)}${viewType
      .substring(1, viewType.length + 1)
      .toLowerCase()}` as "List" | "Map";
  };

  const sendViewLocationsAnalytics = async () => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.ViewLocations,
      AnalyticsPayloadGenerator.viewLocationsPayload(
        previousScreen === "/" ? "Home" : previousScreen,
        locationPermissionGranted ? "True" : "False",
        getStoreSearchViewType()
      )
    );
  };

  const sendSearchLocationsAnalytics = async (autoSuggestTitle: string) => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.SearchLocations,
      AnalyticsPayloadGenerator.searchLocationsPayload(
        searchText,
        autoSuggestTitle,
        getStoreSearchViewType()
      )
    );
  };

  const sendFilterLocationsAnalytics = async (filterName: string) => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.FilterLocations,
      AnalyticsPayloadGenerator.filterLocationsPayload(
        filterName,
        getStoreSearchViewType()
      )
    );
  };

  const handleUpdateSelectedTag = (
    selected: boolean,
    tag: StoreModules.StoreModels.StoreTagLabel
  ) => {
    sendFilterLocationsAnalytics(tag);
    let result: StoreModules.StoreModels.StoreTagLabel[] = [...selectedTags];
    if (selected) {
      //unSelect the button
      result = result.filter((n) => n != tag);
    } else {
      //Select the button
      result.push(tag);
    }
    updateSelectedTags(result);
  };

  const toggleViewType = () => {
    setViewType(
      viewType === StoreModules.StoreModels.RestaurantsViewType.LIST
        ? StoreModules.StoreModels.RestaurantsViewType.MAP
        : StoreModules.StoreModels.RestaurantsViewType.LIST
    );
  };

  const handleOrder = (newStore: StoreReduxModels.Store) => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.OrderNow, {
      screen_origin: Screens.Restaurants,
      store_id: store.selectedStore?.id,
      store_name: store.selectedStore?.name,
      new_store_id: newStore.id,
      new_store_name: newStore.id,
    });

    handleSetStore(newStore);
  };

  const handleSelectedStore = (storeModel: StoreReduxModels.Store) => {
    sendSearchLocationsAnalytics(storeModel.name);
    handleSetStoreInfo(storeModel);
    handleOrder(storeModel);
  };

  const getSearchResult = (
    storeSearchResult: StoreReduxModels.StoreSearchResult
  ): ResultListItem[] => {
    return [
      ...storeSearchResult.fuzzySearchResult.map((n) => {
        return {
          data: n,
          type: StoreModules.StoreModels.ResultListItemType.FUZZY_SEARCH,
        };
      }),
      ...placeResults.map((n) => {
        return {
          data: n,
          type: StoreModules.StoreModels.ResultListItemType.GOOGLE_PLACE,
        };
      }),
    ];
  };

  const handleGetPlacePredictions = (
    predictions?: ApiStoreModel.Prediction[]
  ) => {
    if (!predictions) {
      setplaceResults([]);
      return;
    }
    let googleSearchResult: StoreReduxModels.GooglePlaceSearchResult[] =
      predictions.slice(0, 5).map((n) => {
        return {
          distance: n.distance_meters,
          name: n.structured_formatting.main_text,
          address: n.description,
          placeId: n.place_id,
        };
      });
    if (userLocation) {
      googleSearchResult = googleSearchResult.sort((a, b) => {
        return (a.distance || 0) - (b.distance || 0);
      });
    }
    setplaceResults(googleSearchResult);
  };

  const handleStoreSearchAction = (text: string) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        handleStoreSearch({
          searchText: text,
          location: userLocation,
          version: VERSION,
          stores: stores,
          apiKey: config.googleMapsKey,
          disableGoogleSearch: true,
        });
        if (!text) {
          handleGetPlacePredictions([]);
          return;
        }
        const request: { [key: string]: unknown } = {
          input: text,
          types: ["(regions)"],
          componentRestrictions: { country: VERSION },
        };
        if (userLocation) {
          request.origin = {
            lat: userLocation.latitude,
            lng: userLocation.longitude,
          };
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (autocompleteService as any)?.getPlacePredictions(
          request,
          handleGetPlacePredictions
        );
      }, 1000)
    );
  };

  const handleGetDetail = (data: ApiStoreModel.Result) => {
    handleGooglePlaceDetailSearchSuccess({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      latitude: (data.geometry.location as any).lat(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      longitude: (data.geometry.location as any).lng(),
      version: config.version,
    });
  };

  const handleSelectedRegion = (
    data: StoreReduxModels.GooglePlaceSearchResult
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (placeService as any)?.getDetails(
      { placeId: data.placeId, fields: ["geometry"] },
      handleGetDetail
    );
  };

  const handleClearSearch = () => {
    handleSetStoreSearchLocation(null);
    if (userLocation) {
      sortStoresByDistance({
        ...userLocation,
        version: VERSION,
      });
    }
    setplaceResults([]);
  };

  useEffect(() => {
    const filterResult = StoreModules.StoreFilter.filterStoreByTags(
      selectedTags,
      store.stores
    );
    setStores(filterResult);

    if (filterResult.length <= 0) {
      handleSetMessageToast(t("StoreSearch:alertNoFilteredResults"));
    } else {
      const isAU = config.version === locales.AU;
      const distance = store.stores[0].distance ?? 0;
      const localeDistance = isAU
        ? distance
        : StoreModules.DistanceCalculator.getMiles(distance);

      if (localeDistance > DISTANCETHRESHOLD_AU) {
        handleSetMessageToast(
          t(
            storeSearchLocation !== null
              ? "StoreSearch:alertNoResultsNearby"
              : "StoreSearch:alertNoRestaurantsNearby",
            {
              distanceThreshold: isAU
                ? DISTANCETHRESHOLD_AU / 1000
                : DISTANCETHRESHOLD_US,
            }
          )
        );
      } else {
        handleSetMessageToast(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, store.stores]);

  /** Sort the stores by distance to the user and set the nearest store as selected by default */
  useEffect(() => {
    if (
      !getStoresLoading &&
      userLocation &&
      userLocation.latitude &&
      userLocation.longitude
    ) {
      sortStoresByDistance({
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
        version: config.version,
      });
      setNearestStoreAsSelected({
        nearestStoreThresholdKm: config.locationConfig.nearestStoreThresholdKm,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation, getStoresLoading]);

  /**
   * Gets user's location if it's enabled.
   */
  useEffect(() => {
    const getUserLocationLatLong = async () => {
      getUserLocation()
        .then((data) => {
          if (data) {
            const coordinates = {
              longitude: data.coords.longitude,
              latitude: data.coords.latitude,
            };
            setUserLocation(coordinates);
            sortStoresByDistance({
              ...coordinates,
              version: VERSION,
            });
          }
        })
        .catch(() => {
          dispatch(UserReduxAction.setLocationPermission(false));
        });
    };

    const requestPermissions = async () => {
      const res = await requestLocationPermission();
      if (!res.userLocation) {
        getCurrentLocationPermission()
          .then(async (val) => {
            if (val != "denied") {
              getUserLocationLatLong().catch(() => {
                dispatch(UserReduxAction.setLocationPermission(false));
              });
            }
          })
          .catch(() => {
            dispatch(UserReduxAction.setLocationPermission(false));
          });
      }
    };

    if (locationPermissionGranted) {
      getUserLocationLatLong().catch(() => {
        dispatch(UserReduxAction.setLocationPermission(false));
      });
    } else {
      requestPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locationPermissionGranted]);

  useEffect(() => {
    sendViewLocationsAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType]);

  return (
    <View style={styles.mainContainer}>
      {/* Only if clipboard is not available */}
      <StoreLinkModal
        isVisible={showLinkModal}
        link={storeShareLink}
        onClose={() => setShowLinkModal(false)}
      />

      <View
        style={styles.header}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setHeaderHeight(height);
        }}>
        <div className={"header"}>
          <div className={"header--white"}>
            <div className={"restaurants__store-search"}>
              <div
                style={{
                  marginBottom: 16,
                  flex: 1,
                  display: "flex",
                  paddingLeft: 16,
                }}>
                <StoreSearch
                  searchText={searchText}
                  setSearchText={setSearchText}
                  headerHeight={headerHeight + 60}
                  onClearSearch={handleClearSearch}
                  onSelectedRegion={handleSelectedRegion}
                  onSelectedStore={(data) => {
                    handleSelectedStore(data);
                  }}
                  searchResult={getSearchResult(searchResult)}
                  isFocused={storeSearchFocused}
                  setFocused={(focus) => {
                    setStoreSearchFocused(focus);
                  }}
                  handleStoreSearch={handleStoreSearchAction}
                />

                {(!storeSearchFocused || isTabletScreen) && (
                  <div className={"restaurants__store-search-toggle"}>
                    <ToggleStoresView
                      activeView={viewType}
                      onViewChange={() => toggleViewType()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </View>
      {getStoresLoading && !store.error ? (
        <LoadingScreen loading={getStoresLoading} />
      ) : store.error ? (
        <ErrorView
          heading={store.error.heading}
          message={store.error.message ?? t("StoreSearch:noStoresErrorMessage")}
        />
      ) : (
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.restaurantsContainer}>
          <View style={{ flex: 1 }}>
            <View
              style={
                viewType === StoreModules.StoreModels.RestaurantsViewType.MAP
                  ? styles.mapFilter
                  : {}
              }>
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  backgroundColor: colours.lightestGrey,
                  padding: 1,
                }}>
                <StoreFilter
                  onSelectedUpdate={handleUpdateSelectedTag}
                  selectedTags={selectedTags}
                  storeTags={storeTags}
                />
              </View>
            </View>
            {viewType === StoreModules.StoreModels.RestaurantsViewType.MAP ? (
              <StoresMap
                clearStoreSearch={() => handleSetStoreSearchLocation(null)}
                searchResultApply={storeSearchLocation != null}
                stores={stores}
                withDelivery={true}
                selectedStore={store.selectedStore}
                handleOrder={handleOrder}
                showToast={handleSetMessageToast}
                isUserLocationKnown={locationPermissionGranted}
                latLong={
                  userLocation
                    ? [userLocation.latitude, userLocation.longitude]
                    : undefined
                }
                storeSearchLatLong={
                  storeSearchLocation
                    ? [
                        storeSearchLocation.latitude,
                        storeSearchLocation.longitude,
                      ]
                    : undefined
                }
              />
            ) : (
              <StoresList
                searchResultApply={storeSearchLocation != null}
                location={userLocation}
                stores={stores}
                handleOrder={handleOrder}
                withDelivery={true}
              />
            )}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default Restaurants;
