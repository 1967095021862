import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, TextStyle, View } from "react-native";

import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

export interface OpenClosedProps {
  open: boolean;
  textStyle?: TextStyle;
  closingSoon?: boolean;
  openingSoon?: boolean;
}

const styles = StyleSheet.create({
  text: {
    color: colours.white,
    lineHeight: 15,
  },
  container: {
    alignItems: "center",
    marginRight: Spacing.Thin,
    padding: Spacing.ExtraThin,
    borderRadius: BorderRadius.Highest,
  },
  open: {
    backgroundColor: colours.green,
  },
  closed: {
    backgroundColor: colours.red,
  },
  closingSoon: {
    backgroundColor: colours.yellow,
  },
});

const OpenClosed = ({
  open,
  textStyle,
  closingSoon,
  openingSoon,
}: OpenClosedProps): JSX.Element => {
  const { t } = useTranslation();

  const getStoreStatusText = () => {
    if (closingSoon) {
      return t("StoreSearch:ClosingSoonStore");
    } else if (open) {
      return t("StoreSearch:OpenStore");
    } else if (openingSoon) {
      return t("StoreSearch:OpensSoon");
    } else {
      return t("StoreSearch:ClosedStore");
    }
  };

  return (
    <View
      style={[
        styles.container,
        closingSoon || openingSoon
          ? styles.closingSoon
          : open
            ? styles.open
            : styles.closed,
      ]}>
      <Text
        style={[
          Typography.bodyBold,
          styles.text,
          closingSoon || openingSoon
            ? styles.closingSoon
            : open
              ? styles.open
              : styles.closed,
          textStyle ? textStyle : null,
          closingSoon || openingSoon ? { color: null } : null,
        ]}>
        {getStoreStatusText()}
      </Text>
    </View>
  );
};

export default OpenClosed;
