import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";

import * as MenuModuleUtils from "../../modules/Menu/utils";
import * as PlatformUtils from "../../modules/platformUtils";
import * as StoreModules from "../../modules/Store";
import * as ApiStoreModel from "../../services/api/store/model";
import { OpenClosed } from "../StoreDetails";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  sectionTitle: {
    ...Typography.headline,
    marginBottom: Spacing.Thin,
    marginTop: Spacing.Regular,
  },
  tradingHoursList: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: Spacing.ExtraThin,
  },
  weekday: {
    ...Typography.bodyBold,
    textTransform: "uppercase",
    width: 70,
  },
  hours: {
    ...Typography.body,
    marginRight: Spacing.Light,
  },
  description: {
    ...Typography.btnPrice,
    color: colours.midGrey,
    textTransform: "capitalize",
  },
  close: {
    ...Typography.bodyBold,
    color: colours.red,
  },
  open: {
    marginTop: Spacing.Narrow,
  },
  openCloseLabel: {
    color: "white",
  },
});

interface StoreTradingHoursProps {
  tradinghours: ApiStoreModel.StoreTradingHour[];
  isStoreOpen: boolean;
  isStoreClosingSoon: boolean;
  isStoreOpeningSoon: boolean;
}

const StoreTradingHours: React.FC<StoreTradingHoursProps> = ({
  tradinghours,
  isStoreOpen,
  isStoreClosingSoon,
  isStoreOpeningSoon,
}): JSX.Element => {
  const { t } = useTranslation();
  const today = moment().format("dddd") as ApiStoreModel.StoreDays;

  return (
    <View>
      <Text style={styles.sectionTitle}>{t("StoreSearch:tradingHours")}</Text>
      {tradinghours.map((day: ApiStoreModel.StoreTradingHour, i: number) => (
        <View
          {...PlatformUtils.generateTestID(Platform.OS, "TradingHour")}
          style={styles.tradingHoursList}
          key={i}>
          <Text style={styles.weekday}>{day.dayOfWeek?.slice(0, 3)}</Text>
          {!!day.timePeriods.length && (
            <View>
              {day.timePeriods.map(
                (timePeriod: ApiStoreModel.TimePeriod, j: number) => (
                  <Text
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      "TradingHour-TimePeriod"
                    )}
                    key={j}
                    style={styles.hours}>
                    {StoreModules.StoreUtils.isStoreOpen24Hours(day)
                      ? t("StoreSearch:Open24Hours")
                      : `${StoreModules.StoreUtils.convert24To12Hour(
                          MenuModuleUtils.getOpeningTimeMenuHoursForDay([
                            timePeriod,
                          ])
                        )} - ${StoreModules.StoreUtils.convert24To12Hour(
                          MenuModuleUtils.getClosingTimeMenuHoursForDay([
                            timePeriod,
                          ])
                        )}`}
                  </Text>
                )
              )}
            </View>
          )}
          {(today === day.dayOfWeek || !day.timePeriods.length) && (
            <View
              {...PlatformUtils.generateTestID(
                Platform.OS,
                "TradingHour-OpenClosed"
              )}
              style={styles.open}>
              <OpenClosed
                open={
                  today === day.dayOfWeek
                    ? isStoreOpen
                    : day.timePeriods.length != 0
                }
                closingSoon={isStoreClosingSoon && today === day.dayOfWeek}
                openingSoon={isStoreOpeningSoon}
                textStyle={
                  today !== day.dayOfWeek ? styles.openCloseLabel : null
                }
              />
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

export default StoreTradingHours;
