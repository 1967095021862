import {
  call,
  fork,
  ForkEffect,
  put,
  select,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";

import * as ApiService from "../../services/api/loyalty";
import { AnalyticsAction } from "../analytics/analytics.slice";
import { errorActions } from "../error/error.slice";
import { ErrorResponse } from "../error/models";
import { loyaltyActions, LoyaltyActionType } from "./loyalty.slice";
import { CoffeeLoyalty, IRewards, Loyalty } from "./models";

export function* handleGetUserLoyalty(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: LoyaltyActionType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<any, void, Loyalty> {
  try {
    const orderId = yield select(
      (state) => state.order?.getOrderResponse?.orderId
    );
    yield put(
      loyaltyActions.getUserCoffeeLoyalty(
        orderId as unknown as string | undefined
      )
    );
    const result: Loyalty = yield call(
      ApiService.getUserLoyalty
      // action.payload
    );
    yield put(loyaltyActions.setUserLoyaltyToState(result));
    yield put(
      AnalyticsAction.setCurrentLoyaltyPoints(result?.actualPointsBalance)
    );
  } catch (e) {
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
    yield put(loyaltyActions.error(e.response));
  }
}

export function* watchGetUserLoyalty(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(loyaltyActions.getUserLoyalty.type, handleGetUserLoyalty);
}

export function* handleGetUserRewards(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: LoyaltyActionType
): Generator<unknown, void, IRewards[]> {
  try {
    const result: IRewards[] = yield call(
      ApiService.getUserRewards
      // action.payload
    );
    yield put(loyaltyActions.getUserRewardsSuccess(result));
  } catch (e) {
    yield put(loyaltyActions.getUserRewardsError(e.response));
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchGetUserRewards(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(loyaltyActions.getUserRewards.type, handleGetUserRewards);
}

export function* handleGetUserCoffeeLoyaty(
  action: LoyaltyActionType
): Generator<unknown, void, CoffeeLoyalty> {
  try {
    const result: CoffeeLoyalty = yield call(
      ApiService.getUserCoffeeLoylalty,
      action.payload as string | undefined
    );
    yield put(loyaltyActions.setUserCoffeeLoyalty(result));
  } catch (e) {
    yield put(loyaltyActions.getUserCoffeeLoyaltyError());
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchGetUserCoffeeLoyaty(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    loyaltyActions.getUserCoffeeLoyalty.type,
    handleGetUserCoffeeLoyaty
  );
}

export function* updateActiveReward(
  action: LoyaltyActionType
): Generator<unknown, void, IRewards> {
  yield put(loyaltyActions.updateActiveReward(action.payload as IRewards));
}

export function* clearActiveReward(): Generator<unknown, void> {
  yield put(loyaltyActions.clearActiveReward());
}

export function* watchUpdateActiveReward(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(loyaltyActions.updateActiveReward.type, updateActiveReward);
}

export function* watchClearActiveReward(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(loyaltyActions.clearActiveReward.type, clearActiveReward);
}

const saga = [
  fork(watchUpdateActiveReward),
  fork(watchClearActiveReward),
  fork(watchGetUserLoyalty),
  fork(watchGetUserRewards),
  fork(watchGetUserCoffeeLoyaty),
];

export default saga;
