/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { fork, put, select, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import storeSearchAU from "../../locales/StoreSearch/au.json";
import storeSearcUS from "../../locales/StoreSearch/us.json";
import { storeFuzzySearch } from "../../modules/Store/storeFilter";
import * as ApiService from "../../services/api/store";
import {
  GooglePlaceDetailResponse,
  GooglePlaceResponse,
  OrderTimeSlotsResponse,
  StoreResponse,
} from "../../services/api/store/model";
import { AnalyticsAction } from "../analytics/analytics.slice";
import { buildErrorResponse } from "../error/utils";
import { MenuActions } from "../menu/menu.slice";
import { StateEnum, stateSelector } from "../utils/selector";
import {
  GetMenuStructureRequestProps,
  GetStoreOrderTimeslotsAndOffsetRequestProps,
  GooglePlaceDetailSearch,
  GooglePlaceSearchResult,
  SortStoreByDistanceProps,
  Store,
  StoreSearchPayload,
  StoreSearchResult,
  StoreState,
} from "./models";
import { storeActions, StoreActionsType } from "./store.slice";

//https://stackoverflow.com/questions/58502778/how-to-repair-a-ts2769-no-overload-matches-this-call/58814026#58814026
const call: any = Effects.call;
const GOOGLE_PLACE_MAX_LENGTH = 5;
function* handleGetStores(): Generator<any, void, StoreResponse[]> {
  try {
    const result: StoreResponse[] = yield call(ApiService.getStores);
    yield put(storeActions.getStoresSuccess(result));
  } catch (e) {
    yield put(
      storeActions.handleApiFailure(
        buildErrorResponse(e, {
          au: storeSearchAU.noStoresErrorTitle,
          us: storeSearcUS.noStoresErrorTitle,
        })
      )
    );
  }
}
function* handleGooglePlaceDetailSearch(
  action: StoreActionsType
): Generator<any, void, GooglePlaceDetailResponse> {
  try {
    const payload = action.payload as GooglePlaceDetailSearch;
    const result: GooglePlaceDetailResponse = yield call(
      ApiService.googlePlaceDetailSearch,
      payload.placeId,
      payload.apiKey
    );
    yield put(
      storeActions.googlePlaceDetailSearchSuccess({
        latitude: result.result.geometry.location.lat,
        longitude: result.result.geometry.location.lng,
        version: payload.version,
      })
    );
  } catch (e) {}
}
function* handleGooglePlaceDetailSearchSuccess(
  action: StoreActionsType
): Generator<any, void> {
  try {
    const payload = action.payload as SortStoreByDistanceProps;
    yield put(storeActions.sortStoresByDistance(payload));
    yield put(
      storeActions.setStoreSearchLocation({
        longitude: payload.longitude,
        latitude: payload.latitude,
      })
    );
  } catch (e) {}
}
function* handleGetNearestStore(): Generator<any, void> {
  const storesState = (yield select(
    stateSelector(StateEnum.store)
  )) as StoreState;
  yield put(
    AnalyticsAction.setNearestStore(storesState.stores[0]?.name ?? null)
  );
}
function* handleStoreSearch(
  action: StoreActionsType
): Generator<any, void, GooglePlaceResponse> {
  const payload = action.payload as StoreSearchPayload;
  const result: StoreSearchResult = {
    goolePlaceSearchResult: [],
    fuzzySearchResult: [],
  };
  try {
    const fuzzySearchResult = storeFuzzySearch(
      payload.searchText,
      payload.stores
    );
    result.fuzzySearchResult = fuzzySearchResult;
  } catch (e) {}
  try {
    if (!payload.disableGoogleSearch) {
      const response: GooglePlaceResponse = yield call(
        ApiService.googlePlaceSearch,
        payload.searchText,
        payload.apiKey,
        payload.location
          ? `${payload.location.latitude},${payload.location.longitude}`
          : "",
        payload.version
      );
      let googleSearchResult: GooglePlaceSearchResult[] = response.predictions
        .slice(0, GOOGLE_PLACE_MAX_LENGTH)
        .map((n) => {
          return {
            distance: n.distance_meters,
            name: n.structured_formatting.main_text,
            address: n.description,
            placeId: n.place_id,
          };
        });
      if (payload.location) {
        googleSearchResult = googleSearchResult.sort((a, b) => {
          return a.distance - b.distance;
        });
      }
      result.goolePlaceSearchResult = googleSearchResult;
    }
  } catch (e) {}
  yield put(storeActions.storeSearchSuccess(result));
}

function* handleSetStore(
  action: StoreActionsType
): Generator<any, void, Store> {
  try {
    const payload = action.payload as Store;
    yield put(AnalyticsAction.setStore(payload));
  } catch (e) {}
}

function* handleSelectStoreById(
  action: PayloadAction<GetMenuStructureRequestProps>
): Generator<any, void, Store> {
  const channelId = action.payload.channelId;
  const storeId = action.payload.storeId;
  yield put(MenuActions.getMenuStructure({ channelId, storeId }));
}

function* handleGetStoreOrderTimeslotsAndOffset(
  action: PayloadAction<GetStoreOrderTimeslotsAndOffsetRequestProps>
): Generator<any, void, OrderTimeSlotsResponse> {
  try {
    const curretTimestamp = moment()
      .set({ second: 0, millisecond: 0 })
      .valueOf();

    const {
      orderTimes,
      orderOffset,
      orderTotal: orderTotalRet,
      timeZoneInfo,
      asapTime,
      timestamp: timestampRet,
    } = yield call(
      ApiService.getOrderTimeSlots,
      action.payload.storeId,
      action.payload.basketValue,
      curretTimestamp
    );
    const asapTimeRet = asapTime
      ? moment.tz(asapTime, timeZoneInfo.storeTimeZone).toDate()
      : null;

    yield put(
      storeActions.setStoreOrderTimeslotsAndOffsetSuccess({
        orderTimes,
        orderOffset,
        basketValue: orderTotalRet,
        timeZoneInfo,
        asapTime: asapTimeRet,
        timestamp: timestampRet,
      })
    );
  } catch (e) {
    // TODO: Sentry error logging
    console.error(e);
    yield put(
      storeActions.setStoreOrderTimeslotsAndOffsetFailure(
        buildErrorResponse(e, {
          au: storeSearchAU.noStoresErrorTitle,
          us: storeSearcUS.noStoresErrorTitle,
        })
      )
    );
  }
}

export function* watchGetStores() {
  yield takeLatest(storeActions.getStores.type, handleGetStores);
}
export function* watchStoreSearch() {
  yield takeLatest(storeActions.storeSearch.type, handleStoreSearch);
}
export function* watchGooglePlaceDetailSearch() {
  yield takeLatest(
    storeActions.googlePlaceDetailSearch.type,
    handleGooglePlaceDetailSearch
  );
}
export function* watchGooglePlaceDetailSearchSuccess() {
  yield takeLatest(
    storeActions.googlePlaceDetailSearchSuccess.type,
    handleGooglePlaceDetailSearchSuccess
  );
}
export function* watchSetStore() {
  yield takeLatest(storeActions.setStore.type, handleSetStore);
}
export function* watchSelectStoreById() {
  yield takeLatest(storeActions.selectStoreById.type, handleSelectStoreById);
}
export function* watchGetNearestStore() {
  yield takeLatest(
    storeActions.sortStoresByDistance.type,
    handleGetNearestStore
  );
}
// getStoreOrderTimeslotsAndOffset
export function* watchGetStoreOrderTimeslotsAndOffset(): Generator<
  any,
  void,
  OrderTimeSlotsResponse
> {
  yield takeLatest(
    storeActions.setStoreOrderTimeslotsAndOffset.type,
    handleGetStoreOrderTimeslotsAndOffset
  );
}

const saga = [
  fork(watchGetStores),
  fork(watchStoreSearch),
  fork(watchGooglePlaceDetailSearch),
  fork(watchGooglePlaceDetailSearchSuccess),
  fork(watchSetStore),
  fork(watchSelectStoreById),
  fork(watchGetNearestStore),
  fork(watchGetStoreOrderTimeslotsAndOffset),
];

export default saga;
