import "swiper/css";
import "swiper/css/pagination";
import "./index.css";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import { SamplingContext } from "@sentry/types";
import React from "react";
import { createRoot } from "react-dom/client";
import SwiperCore, { Pagination } from "swiper";

import config from "config";
import { configAnalytics } from "gyg_common/analytics";
import { CHECKOUT_MODAL_ERROR_DISPLAYED } from "gyg_common/components/modals/CheckoutErrorModal";
import { configSentryLogger, ignoreErrors } from "gyg_common/sentry";

import { Analytics } from "./analytics";
import App from "./App";
import { SentryLogger } from "./logging/SentryLogger";
import * as serviceWorker from "./serviceWorkerRegistration";

const release =
  process.env.REACT_APP_COMMIT_SHA && process.env.REACT_APP_COMMIT_SHA !== ""
    ? { release: `web.${process.env.REACT_APP_COMMIT_SHA}` }
    : {};

const tracesSampler = (samplingContext: SamplingContext): number | boolean => {
  if (process.env.NODE_ENV !== "production") {
    return 0;
  }

  const eventName = samplingContext.transactionContext.name;
  const isCheckoutErrorRetry =
    eventName.indexOf(CHECKOUT_MODAL_ERROR_DISPLAYED) > 0;

  if (isCheckoutErrorRetry) {
    // higher sampler rate for checkout errors
    return 0.5;
  } else {
    return 0.1;
  }
};

if (!__DEV__) {
  Sentry.init({
    ...release,
    dsn: config.sentry.dsn,
    debug: process.env.NODE_ENV !== "production",
    environment: config.sentry.env,
    enabled: process.env.NODE_ENV === "production",
    tracesSampler,
    // tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 0,
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // replays limited to 500 only so don't sample non-error
    replaysSessionSampleRate: 0,
    // only 0.1% on errorneous sessions will be replay captured
    replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 0.001 : 0,
    ignoreErrors,
    integrations: [
      // Add browser profiling integration to the list of integrations
      new Sentry.BrowserProfilingIntegration(),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],
  });
}

configSentryLogger(new SentryLogger());
configAnalytics(new Analytics());

// install Swiper modules
SwiperCore.use([Pagination]);

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
const isProd = process.env.NODE_ENV === "production";
// temp disable StrictMode for development mode
// since google-map-react is not fully react 18 compatible
// https://github.com/google-map-react/google-map-react/issues/1129
const WebApp = isProd ? (
  <React.StrictMode>
    <App />
  </React.StrictMode>
) : (
  <App />
);
root.render(WebApp);

// Register for service worker
serviceWorker.register({
  onUpdate: (registration) => {
    console.debug(
      "SW: sending SKIP_WAITING message reload browser immediately"
    );
    // Old serviceworker will be stopped/new serviceworker started without reload
    if (registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  },
});
