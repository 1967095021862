import "./styles.scss";

import * as braze from "@braze/web-sdk";
import * as Sentry from "@sentry/react";
import { utag } from "analytics";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import WebAuthObsever from "RouterObserver/WebAuthObsever";
// Fixed no smooth scrolling between menu items in Safari browser.
import { polyfill } from "seamless-scroll-polyfill";

import config from "config";
import {
  DashboardReduxAction,
  FavouriteReduxAction,
  LoyaltyActions,
  OrderReduxAction,
  RootState,
  StoreReduxAction,
  UserReduxAction,
} from "gyg_common";
import { ErrorBoundary } from "gyg_common/components";
import commonConfig from "gyg_common/config";
import { useLoginTrack } from "gyg_common/hooks/useLoginTrack";
import { createRatingOrder } from "gyg_common/modules/Order/utils";
import { CheckoutResponse } from "gyg_common/redux_store/checkout/model";
import Routes from "navigation";
import { Screens } from "navigation/const";
import Footer from "views/components/shared/Footer";
import { TermsAndConditionsModalContainer } from "views/containers/UserProfile/TermsAndConditionsModalContainer/TermsAndConditionsModalContainer";

import BranchAndModalsContainer from "../BranchAndModals/BranchAndModalsContainer";
import { RateExperienceContainer } from "../RateExperience/RateExperienceContainer";
import { MessageToastContainer } from "../shared/MessageToast/MessageToastContainer";
import { MobileVerificationContainer } from "../shared/MobileVerificationContainer/MobileVerificationContainer";

import FeatureFlagContainer from "@/../../packages/common/containers/FeatureFlag/FeatureFlagContainer";

polyfill();

/**
 * Gets all data that are needed at any initial view's load.
 * For example: stores and user authentication.
 * @returns
 */
const BaseContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const userSessionId = useRef<string>();

  const { stores, error } = useSelector((state: RootState) => state.store);
  const { isShowLogin, isAuthSuccess, currentUser } = useSelector(
    (state: RootState) => state.login
  );
  const { mobileAppConfig } = useSelector((s: RootState) => s.dashboard);
  const { profile, isBrazeInitialised } = useSelector(
    (state: RootState) => state.user
  );
  const { recentOrders, currentOrders } = useSelector(
    (state: RootState) => state.order
  );
  const guestOrders = useSelector((state: RootState) => state.guest.orders);
  const [showRating, setShowRating] = useState<boolean>(false);

  useLoginTrack(null);

  const goToTermsAndConditions =
    mobileAppConfig?.termsAndConditions || config.termsAndConditionsUrl;

  const goToPrivacyPolicyUrl =
    mobileAppConfig?.privacyPolicy || config.privacyPolicyUrl;

  const authenticateUser = useCallback(() => {
    let currentBrazeId: null | string | undefined = null;
    const brazeUser = braze.getUser();
    if (!!brazeUser) {
      currentBrazeId = brazeUser.getUserId();
    }

    const sessionId = commonConfig.sessionId;
    userSessionId.current = sessionId;
    if (!__DEV__) {
      Sentry.setUser({
        brazeId: currentUser ? currentUser.brazeId : undefined,
        sessionId,
      });
      Sentry.setTag("user.sessionId", sessionId);
    }

    const isBrazeIdDifferentInCurrentUser =
      currentUser?.brazeId && currentBrazeId !== currentUser.brazeId;
    const isBrazeIdDifferentInProfile =
      profile?.brazeId && currentBrazeId !== profile.brazeId;

    if (isBrazeIdDifferentInCurrentUser || isBrazeIdDifferentInProfile) {
      // changes user for current user braze id
      // or fallbacks to profile braze id
      if (currentUser?.brazeId) {
        braze.changeUser(currentUser?.brazeId);
      } else if (profile?.brazeId) {
        braze.changeUser(profile?.brazeId);
      }

      braze.requestPushPermission();
    }

    batch(() => {
      dispatch(FavouriteReduxAction.getFavourite());
      dispatch(LoyaltyActions.getUserRewards());
      dispatch(OrderReduxAction.getAuthenticatedOrders());
    });

    utag.loader.SC("utag_guzmanTealiumMain", {
      logged_in: true,
    });
  }, [currentUser, dispatch, profile?.brazeId]);

  const unAuthenticateUser = () => {
    //sets logged_in flag to false
    utag.loader.SC("utag_guzmanTealiumMain", {
      logged_in: false,
    });

    //deletes customer_id flag
    utag.loader.SC(
      "utag_guzmanTealiumMain",
      {
        customer_id: "",
      },
      "d"
    );
  };

  //
  // Monitoring of user auth state
  //
  useEffect(() => {
    if (
      isBrazeInitialised &&
      isAuthSuccess &&
      (currentUser?.brazeId || profile?.brazeId)
    ) {
      authenticateUser();
    } else {
      unAuthenticateUser();
    }
  }, [
    isAuthSuccess,
    currentUser?.brazeId,
    profile?.brazeId,
    isBrazeInitialised,
    authenticateUser,
  ]);

  useEffect(() => {
    if (isAuthSuccess) {
      dispatch(LoyaltyActions.getUserLoyalty());
    }
  }, [dispatch, isAuthSuccess]);

  useEffect(() => {
    if (profile?.id) {
      utag.loader.SC("utag_guzmanTealiumMain", {
        logged_in: true,
        customer_id: profile.id,
      });
    }
  }, [profile]);

  /**load config from the server **/
  useEffect(() => {
    dispatch(DashboardReduxAction.getMobileAppConfig());
  }, [dispatch]);

  /** Call to get all stores. This call should be first */
  useEffect(() => {
    if (!stores.length && !error) {
      dispatch(StoreReduxAction.getStores());
    }
  }, [stores, error, dispatch]);

  useEffect(() => {
    if (isShowLogin) {
      history.push(Screens.Login, { loginNavigatesTo: location.pathname });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowLogin]);

  /**
   * Controls window overflow.
   * Special case is menu view where overlow is set up locally on lower level of container to not interfere with sticky header.
   * Overflow is needed due to hidden modals which are extending html length.
   */
  useEffect(() => {
    if (location.pathname === Screens.Menu && ref.current) {
      ref.current.style.overflow = "initial";
    } else if (ref.current) {
      ref.current.style.overflow = "hidden";
    }
  }, [location.pathname]);

  /**
   * Initialise braze after a 4 second delay,
   * this was added to try and
   * avert braze sessions from bots/scrapers.
   */
  useEffect(() => {
    const brazeInitTimeout = setTimeout(() => {
      braze.initialize(config.braze.apikey, {
        baseUrl: config.braze.endpoint,
        manageServiceWorkerExternally: true,
        minimumIntervalBetweenTriggerActionsInSeconds: 1,
        sessionTimeoutInSeconds: 30,
      });
      braze.openSession();
      dispatch(UserReduxAction.setIsBrazeInitialised(true));
    }, 4000);

    return () => {
      clearTimeout(brazeInitTimeout);
    };
  }, [dispatch]);

  /**
   * Subsribe on Braze messages and wait the rating message
   */
  useEffect(() => {
    let inAppSubscription: string | undefined;
    if (isBrazeInitialised) {
      // When Braze InAppMessage does not contain feed_type we allow the Braze SDK UI to display the message
      inAppSubscription = braze.subscribeToInAppMessage((message) => {
        const m = message as braze.InAppMessage;
        if (!(m && m.extras?.feed_type)) {
          braze.showInAppMessage(m);
        }

        if (m && m.extras?.feed_type && m.extras?.feed_type === "rating") {
          const orderId = m.extras.order_id;
          const allOrders = guestOrders.concat(recentOrders, currentOrders) as [
            CheckoutResponse,
          ];
          const ratingOrder = allOrders.find(
            (order) => order.order.orderId === orderId
          );
          if (ratingOrder) {
            const ratingObject = createRatingOrder(ratingOrder);
            dispatch(OrderReduxAction.updateRatedOrderAll(ratingObject));
            braze.logInAppMessageImpression(m);
            setShowRating(true);
          }
        }
      });
    }
    return () => {
      if (inAppSubscription) {
        braze.removeSubscription(inAppSubscription);
      }
    };
  }, [dispatch, isBrazeInitialised, recentOrders, currentOrders, guestOrders]);

  return (
    <ErrorBoundary>
      <FeatureFlagContainer>
        <div className='base-container' id='base-container' ref={ref}>
          <BranchAndModalsContainer />
          <WebAuthObsever />
          <RateExperienceContainer
            showRating={showRating}
            setShowRating={setShowRating}
          />

          <div className='base-container__page'>
            <Routes />
            <MessageToastContainer />
            <TermsAndConditionsModalContainer />
            <MobileVerificationContainer />
          </div>
          <Footer
            termsConditionsUrl={goToTermsAndConditions}
            privacyPolicyUrl={goToPrivacyPolicyUrl}
          />
        </div>
      </FeatureFlagContainer>
    </ErrorBoundary>
  );
};

export default BaseContainer;
